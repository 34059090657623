import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { componentService } from '../../services/component.service'
import { projectService } from '../../services/project.service'

export interface ComponentsState {
    status: string
    results: any
    count: number
    next: any
    previous: any
}

const initialState: ComponentsState = {
    status: 'idle',
    results: [],
    count: 0,
    next: null,
    previous: null,
}

// Async function for uploading the sequences.
export const loadComponents = createAsyncThunk(
    'energySystem/getComponents',
    async (data: { energySystemId: string; page: number }) => {
        const { energySystemId, page = 1 } = data
        const response = await componentService.getComponents(
            energySystemId,
            page
        )
        return response.data
    }
)

export const componentsSlice = createSlice({
    name: 'components',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetComponents: (state: any) => {
            state.status = 'idle'
            state.results = []
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder: any) => {
        builder
            .addCase(loadComponents.pending, (state: any) => {
                state.status = 'loading'
            })
            .addCase(loadComponents.fulfilled, (state: any, action: any) => {
                state.status = 'idle'
                state.results = action.payload.results
                state.count = action.payload.count
                state.next = action.payload.next
                state.previous = action.payload.previous
            })
    },
})

export const { resetComponents } = componentsSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectComponents = (state: RootState) => state.components

export default componentsSlice.reducer
