import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import ImageHero from '../assets/img/hero.svg'
import ImageTeam from '../assets/img/12.svg'
import ImageSuccess from '../assets/img/13.svg'

import { Button, Card, CardContent, Avatar, Badge } from '@mui/material'
import { AuthContext } from '../context/AuthContext'
import { MenuIcon } from '../assets/icons'
import CoverImage from '../components/General/CoverImage'
import LoginForm from '../components/Forms/LoginForm'
import GoogleAuthForm from '../components/Forms/GoogleAuthForm'

interface SectionProps {
    children: any
    classes?: string
}

function SectionTwoBlocks(props: SectionProps) {
    const { children, classes } = props
    return (
        <section
            className={classes ? classes : 'flex justify-center py-8 md:py-12'}
        >
            <div className="w-full md:w-11/12 lg:w-3/4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
                    {children}
                </div>
            </div>
        </section>
    )
}

function NavbarContent() {
    const { user } = useContext(AuthContext)

    return (
        <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
            {/*{!user && (*/}
            {/*    <Link to="/auth/login">*/}
            {/*        <Button className="w-full">Log In</Button>*/}
            {/*    </Link>*/}
            {/*)}*/}
            {/*{!user && (*/}
            {/*    <Link to="/auth/create-account">*/}
            {/*        <Button variant={'outlined'} className="w-full">*/}
            {/*            Sign Up*/}
            {/*        </Button>*/}
            {/*    </Link>*/}
            {/*)}*/}
            {user && (
                <Link to="/app">
                    <Button className="w-full">Dashboard</Button>
                </Link>
            )}
        </div>
    )
}

function DesktopNavbar() {
    return (
        <aside className="hidden w-full lg:block">
            <div className="flex justify-center">
                <div className="w-3/4">
                    <div className="py-4 text-gray-500">
                        <div className="flex-1 flex items-center justify-between">
                            <Link
                                to="/"
                                className="text-xl font-bold text-gray-800"
                            >
                                BrightMerge
                                <a
                                    onClick={() => {
                                        window.open(
                                            'https://brightmerge.com',
                                            '_blank'
                                        )
                                    }}
                                >
                                    <span className="block text-sm ml-2 text-gray-600 font-light">
                                        By Brightmerge
                                    </span>
                                </a>
                            </Link>
                            <NavbarContent />
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    )
}

function MobileNavbar() {
    const [isNavbarOpen, setNavbarOpen] = React.useState(false)

    const toggleNavbar = () => {
        setNavbarOpen(!isNavbarOpen)
    }

    return (
        <aside className="flex justify-center">
            <div className="w-full md:w-11/12 lg:hidden">
                <div className="px-4 py-4 text-gray-500">
                    <div className="flex flex-col space-y-4">
                        <div className="flex justify-between">
                            <Link
                                to="/"
                                className="text-xl font-bold text-gray-800"
                            >
                                BrightMerge
                            </Link>
                            <button
                                className="p-1 rounded-md focus:outline-none focus:shadow-outline-purple"
                                onClick={toggleNavbar}
                                aria-label="Menu"
                            >
                                <MenuIcon
                                    className="w-6 h-6"
                                    aria-hidden="true"
                                />
                            </button>
                        </div>

                        {isNavbarOpen && <NavbarContent />}
                    </div>
                </div>
            </div>
        </aside>
    )
}

function LandingMinimal() {
    const navigate = useNavigate()

    const navigateToDashboard = () => {
        navigate('/app')
    }
    return (
        <>
            <DesktopNavbar />
            <MobileNavbar />
            <main>
                <CoverImage>
                    <div className="lg:pt-4">
                        <SectionTwoBlocks classes="flex justify-center py-8 md:py-6">
                            <div className="flex flex-col px-4 lg:px-0">
                                <h1 className="mb-4 text-4xl font-bold text-gray-100 uppercase">
                                    Ready to get plugged in?
                                </h1>
                            </div>

                            <div className="h-fit bg-gray-100 rounded-xl md:mt-12 justify-end">
                                <div className="rounded-t-xl bg-gray-800 p-8 text-gray-100 text-3xl border-none">
                                    <h2 className="text-center">
                                        <span className=""> BrightMerge</span>
                                    </h2>
                                    <h2 className="text-center text-2xl">
                                        <span className="">
                                            Get started for free.
                                        </span>
                                    </h2>
                                    <div className="text-center mt-2">
                                        <Link to="/auth/create-account">
                                            <Button
                                                size={'large'}
                                                variant="outlined"
                                                sx={{
                                                    color: '#d9f99d',
                                                    borderColor: '#d9f99d',
                                                }}
                                            >
                                                sign up
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="py-8 px-12">
                                    <h2 className="text-md mb-4 text-center">
                                        Already have an account?, log in.
                                    </h2>
                                    <LoginForm
                                        onSuccess={navigateToDashboard}
                                    />
                                    <hr className="my-4" />
                                    <GoogleAuthForm />
                                </div>
                            </div>
                        </SectionTwoBlocks>
                    </div>
                </CoverImage>
            </main>

            <footer>
                <SectionTwoBlocks>
                    <div className="flex items-center lg:w-3/4 px-4">
                        <h1 className="mb-4 text-2xl font-semibold text-gray-700">
                            Optimize Your Electric Vehicle Fleet And The Energy
                            It Needs With A Smart Cloud-based Solution.
                        </h1>
                    </div>
                    <div className="flex justify-end px-4">
                        <div className="flex flex-row w-full lg:w-3/4 justify-between">
                            <div>
                                <p className="mb-2 font-semibold text-gray-700 uppercase">
                                    Useful Links
                                </p>
                                <div className="grid grid-cols-1 text-right text-sm text-gray-600">
                                    <a
                                        href="#pablo"
                                        className="hoverr:text-gray-800"
                                    >
                                        Customer Service
                                    </a>
                                    <a
                                        href="#pablo"
                                        className="hoverr:text-gray-800"
                                    >
                                        Video Tutorials
                                    </a>
                                </div>
                            </div>
                            <div>
                                <p className="mb-2 font-semibold text-gray-700 uppercase">
                                    Other Resources
                                </p>
                                <div className="grid grid-cols-1 text-right text-sm text-gray-600">
                                    <Link
                                        className="hover:text-gray-800"
                                        to="/auth/privacy-policy"
                                    >
                                        Privacy Policy
                                    </Link>
                                    <a
                                        href="#tbd"
                                        className="hover:text-gray-800"
                                    >
                                        Terms of Service
                                    </a>
                                    <a
                                        href="#tbd"
                                        className="hover:text-gray-800"
                                    >
                                        Trademark Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </SectionTwoBlocks>
            </footer>
        </>
    )
}

export default LandingMinimal
