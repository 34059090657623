const Theme = {
    button: {
        primary: {
            base: 'text-white bg-lime-600 border border-transparent',
            active: 'active:bg-lime-600 hover:bg-lime-700 focus:ring focus:ring-lime-300',
            disabled: 'opacity-50 cursor-not-allowed',
        },
        outline: {
            base: 'text-gray-600 border-gray-300 border focus:outline-none',
            active: 'active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:ring focus:ring-gray-300',
            disabled: 'opacity-50 cursor-not-allowed bg-gray-300',
        },
    },
    input: {
        base: 'rounded-lg bg-white border border-gray-300 text-gray-900 focus:ring-lime-500 focus:border-lime-500 block flex-1 min-w-0 w-full text-sm p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-lime-500 dark:focus:border-lime-500',
        checkbox:
            'text-lime-600 form-checkbox focus:border-lime-400 focus:outline-none focus:ring focus:ring-lime-300 focus:ring-offset-0 rounded dark:focus:ring-gray-300',
    },
}

export default Theme
