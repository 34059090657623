import React from 'react'
import { Link } from 'react-router-dom'
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import LightningImg from '../assets/img/lightning.svg'
import { Button } from '@windmill/react-ui'
import RocketImage from '../assets/img/rocket.svg'

function Page404() {
    return (
        <div className="m-6">
            <div className="flex flex-col items-center bg-white border rounded-lg shadow-md md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
                <img
                    className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                    src={RocketImage}
                    alt=""
                />
                <div className="flex flex-col justify-between p-4 leading-normal">
                    <h1 className="text-6xl font-semibold text-gray-700 dark:text-gray-200">
                        404
                    </h1>
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        Oh Snap!
                    </h5>
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        Looks like there is no energy here.{' '}
                        <Link
                            className="text-purple-600 hover:underline dark:text-purple-300"
                            to="/"
                        >
                            go back
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Page404
