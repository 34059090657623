import { axiosPrivate, axiosPublic } from '../../api/connector'

export function refreshAccessToken(refreshToken: string | undefined) {
    return axiosPublic.post(`/v1/auth/token/refresh/`, {
        refresh: refreshToken,
    })
}

export function getSASToken(blobName: string) {
    return axiosPrivate.post(`/v1/obtain-sas-token/`, {
        blob_name: blobName,
    })
}
