import React, { useContext, Suspense, useEffect, lazy } from 'react'
import { Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom'
import routes from '../../routes'

import Sidebar from '../Sidebar'
import Header from '../layout/Header'
import Main from '../layout/Main'
import ThemedSuspense from '../General/ThemedSuspense'
import { SidebarContext } from '../../context/SidebarContext'

function Layout() {
    const { isSidebarOpen, closeSidebar } = useContext(SidebarContext)
    let location = useLocation()

    useEffect(() => {
        closeSidebar()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <div
            className={`flex min-h-screen bg-gray-50 dark:bg-gray-900 ${
                isSidebarOpen && 'overflow-hidden'
            }`}
        >
            <Sidebar />

            <div className="flex flex-col flex-1 w-full">
                <Header />
                <Main>
                    <Suspense fallback={<ThemedSuspense />}>
                        <Outlet />
                    </Suspense>
                </Main>
            </div>
        </div>
    )
}

export default Layout
