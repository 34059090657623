import routes from '../../routes/sidebar'
import { NavLink, Link, useLocation } from 'react-router-dom'
import SidebarSubmenu from './SidebarSubmenu'
import MenuIcon from '../Icons/MenuIcon'
import { useAppSelector } from '../../app/hooks'
import { selectAuth } from '../../features/auth/authSlice'

const ActiveNavLinkIndicator = ({ to }: { to: any }) => {
    const location = useLocation()
    let match = location.pathname.includes(to) // useMatch({path: resolved.pathname, end: true});

    return (
        <>
            {match && (
                <span
                    className="absolute inset-y-0 left-0 w-1 bg-lime-600 rounded-tr-lg rounded-br-lg"
                    aria-hidden="true"
                ></span>
            )}
        </>
    )
}

function SidebarContent() {
    const { user } = useAppSelector(selectAuth)
    const filteredRoutes = routes.filter((route: any) => {
        return !route.is_staff || (route.is_staff && user && user.is_staff)
    })

    return (
        <div className="py-4 text-gray-500 dark:text-gray-400">
            <Link
                to="/"
                className="ml-6 text-lg font-bold text-gray-800 dark:text-gray-200"
            >
                BrightMerge
            </Link>
            <a
                onClick={() => {
                    window.open('https://brightmerge.com', '_blank')
                }}
            >
                <span className="block text-sm ml-6 text-gray-600 font-light cursor-pointer dark:text-white">
                    By Brightmerge
                </span>
            </a>
            {user && user.is_staff && (
                <div className="my-2 uppercase text-center p-2 text-white bg-lime-800 dark:bg-lime-800 dark:text-lime-50 font-semibold border-lime-600">
                    Customer Success
                </div>
            )}
            <ul className="mt-6">
                {filteredRoutes.map((route, i) =>
                    route.routes ? (
                        <SidebarSubmenu route={route} key={route.name} />
                    ) : route.name ? (
                        <li className="relative px-6 py-3" key={route.name}>
                            <NavLink
                                to={route.path}
                                className={(active) =>
                                    active
                                        ? 'inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200 text-gray-800 dark:text-gray-100'
                                        : 'inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200'
                                }
                            >
                                <ActiveNavLinkIndicator to={route.path} />
                                <MenuIcon
                                    className="w-5 h-5"
                                    aria-hidden="true"
                                    icon={route.icon}
                                />
                                <span className="ml-4">{route.name}</span>
                            </NavLink>
                        </li>
                    ) : (
                        <li className="relative px-6 py-3" key={i}>
                            <hr />
                        </li>
                    )
                )}
            </ul>
        </div>
    )
}

export default SidebarContent
