import React from 'react'
import LoginImage from '../assets/img/brmLogin.png'
import ProfileImage from '../assets/img/brmProfiless.png'
import ProfileImage2 from '../assets/img/brmProfiless2.png'
import ProfileImageDark from '../assets/img/brmProfilessDark.png'
import PrjectDashborad from '../assets/img/brmProjectDashboardpng.png'
import PrjectDashborad2 from '../assets/img/brmProjectDashboardpng2.png'
import CreateProjectImage from '../assets/img/brmCreateProject.png'
import Sidebar from '../assets/img/sidbar.png'
import ProjectLocationImage from '../assets/img/ProjectLocation.png'
import EditImage from '../assets/img/edit.png'
import DeleteImage from '../assets/img/deleteImage.png'
import BuildProjectImage from '../assets/img/buildProject.png'
import BuildProjectImage2 from '../assets/img/buildProject2.png'
import evFleet from '../assets/img/evFleet.png'
import evFleet2 from '../assets/img/evFleet2.png'
import evFleet3 from '../assets/img/evFleet3.png'
import ProjectList from '../assets/img/ProjectList.png'
import AddCargingHub from '../assets/img/addCargingHub.png'
import AddCargingHub2 from '../assets/img/addCargingHub2.png'
import AddCargingHub3 from '../assets/img/addCargingHub3.png'
import AddCargingHub4 from '../assets/img/addCargingHub4.png'
import EnergySystem from '../assets/img/engrySystem.png'
import EnergySystem1 from '../assets/img/energySystemStep1.png'
import EnergySystem2 from '../assets/img/energySystemStep2.png'
import EnergySystem3 from '../assets/img/energySystemStep3.png'

function Documentation() {
    return (
        <div className="mt-3 mb-4 pb-10 dark:text-white">
            <h1 className="text-3xl font-bold mb-3">
                User Guide - Brightmerge Platform
            </h1>
            <hr></hr>
            <div className="mt-3 flex justify-center text-start flex-col gap-5">
                <h2 className="text-2xl my-4 font-bold">Login</h2>
                <div className="mt-3  w-full imageContainer">
                    <img src={LoginImage} className="w-full" />
                </div>
                <p className="mt-3">
                    If you forget your password, you can reset it via the Forgot
                    your password link. <br></br> If you do not have an account,
                    you can create an account via the Create account link or
                    request from the project administrator.
                </p>

                <h2 className="text-2xl my-4 font-bold">Edit Profile</h2>
                <span>
                    By clicking on your profile initials and then Profile you
                    can edit your <b>profile</b> information.
                </span>
                <div className="mt-3 imageContainer">
                    <img src={ProfileImage} className="w-full" />
                    <img src={ProfileImage2} className="w-full mt-3" />
                </div>
                <span className="mb-12 mt-3">
                    Here you can edit your user name, email address, and change
                    your password.
                </span>
                <div className="imageContainer">
                    <img src={ProfileImageDark} className="w-full" />
                </div>
                <span className="mb-12 mt-3">
                    You can also change from Light to Dark theme at the bottom
                    of the page.
                </span>

                <h2 className="text-2xl font-bold mb-4">Project dashboard</h2>
                <span className="my-4">
                    Upon log in, you will arrive at the Project dashboard. Here
                    you will see a list of the projects you are working on. The
                    list can be viewed in list view or in tile view.
                </span>
                <div className="imageContainer">
                    <img
                        src={PrjectDashborad}
                        className="w-full object-contain"
                    />
                    <img
                        src={PrjectDashborad2}
                        className="w-full object-contain mt-4"
                    />
                </div>
                <p className="mt-3">
                    From the Projects dashboard you can create a new project by
                    clicking on Create Project, edit existing projects and
                    delete projects.{' '}
                </p>
                <p className="my-4">
                    From the omni present menu on the left side you can jump to
                    other areas of the platform such as the <b>Learn</b> and{' '}
                    <b>Customer</b>
                    Support areas and Logout when you are done working on the
                    platform
                </p>

                <div className="imageContainer">
                    <img src={Sidebar} className="w-1/3 object-contain" />
                </div>
                <h2 className="mt-10 mb-4 text-2xl font-bold">
                    Create Project
                </h2>
                <div className="imageContainer">
                    <img
                        src={CreateProjectImage}
                        className="w-full object-contain"
                    />
                </div>
                <div className="">
                    <p className="my-4">
                        <b>Name:</b> enter the name of your project <br></br>{' '}
                        <b>Description:</b> enter a description of your project,
                        we find it helpful to add a few details when you know
                        them such as EV type and quantity, storage system size
                        and quantity, quantity and size of solar arrays included
                        within the project.<br></br> <b>Goal:</b> this is where
                        you can add the end goal of the project, perhaps you
                        have a financial goal in mind, or a start date of the
                        project or a sustainability target that you need to
                        reach. Under <b>Stage</b>, select the stage the current
                        project is in.
                    </p>
                </div>

                <h2 className="mt-10 mb-4 text-2xl font-bold">
                    Create Location
                </h2>
                <p>
                    Enter an address, geo coordinates, or search manually using
                    google maps to set the location of the project. Once you
                    have found the site location, you can edit the map view to
                    zoom into or out of the map, switch to a satellite viw, or a
                    3D view if available in google maps.
                </p>
                <div className="imageContainer my-4">
                    <img
                        src={ProjectLocationImage}
                        className="w-full object-contain"
                    />
                </div>
                <p className="py-3">
                    <b>Design:</b> in this stage a design has been settled on
                    and can be used to quote against or to have vendors provide
                    quotes
                </p>
                <p className="py-3">
                    <b>Final design: </b> once quotes are received, the design
                    should be updated to match the quotes from all aspects
                    including technical and financial. This will provide the
                    most accurate planning from the platform.
                </p>
                <p className="py-2">
                    <b>Construction:</b> many projects will need to change
                    during construction, perhaps a construction task takes
                    longer or is more costly due to unforseen site issues, or
                    hardware needs to be switched due to supply issues, in this
                    phase you can update the project technically and financially
                    to understand the impact of such changes.
                </p>
                <p className="py-2">
                    <b>As Built:</b> In this phase the project is ready for
                    commissioning into operation. During this phase changes can
                    occur if there are construction issues, design issues, or
                    communication issues on site between all the energy assets.
                </p>
                <p className="py-2">
                    <b>Operation:</b>Once the site is finished with
                    commissioning, the project is set to operation. In this
                    phase the platform analyzes the performance of the site and
                    provides updated plans and financial information.
                </p>

                <h2 className="mt-10 mb-4 text-2xl font-bold">Lifetime</h2>
                <p>
                    Each project will have a unique <b>Lifetime</b>, the
                    duration the project is in an <b>Operation</b> state. You
                    can enter the number of years that a project will be in
                    Operation and this will set the length of time the financial
                    analysis will occur. You can enter a project lifetime up to
                    25 years. <br />
                    <br /> <b>Contact</b> is where you can fill in the primary
                    contact for this project, this is typically the admin user
                    of the platform who is actively working on the design and
                    development of the project.
                </p>
                <h2 className="mt-10 mb-4 text-2xl font-bold">
                    Business Use Cases
                </h2>
                <p>
                    You have two options to select from for the business use
                    case:
                </p>
                <h2 className="mt-10 mb-4 text-2xl font-bold">
                    Design EV Charging Hub
                </h2>
                <p>
                    When you choose Design EV Charging Hub the platform will
                    focus on designing the charging hub.
                </p>
                <h2 className="mt-10 mb-4 text-2xl font-bold">
                    Design Energy System
                </h2>
                <p>
                    When you choose Design Energy System the system will design
                    the distributed energy system.
                </p>
                <h2 className="mt-10 mb-4 text-2xl font-bold">
                    Charging Models
                </h2>
                <p>You have three options to analyze charging options</p>
                <h2 className="mt-10 mb-4 text-2xl font-bold">
                    Public Charging
                </h2>
                <p>
                    This function is not yet available within the system and
                    therefore it is not available for selection.
                </p>

                <h2 className="mt-10 mb-4 text-2xl font-bold">
                    Charging Window
                </h2>
                <p>
                    This Charging Model will utilize a time period per vehicle
                    group that the group is allowed to charge. For example, if
                    you have a vehicle group A and assign it a charging window
                    between 01:00 am until 06:00 am, the system will schedule
                    all charging sessions for vehicle group A to occur within
                    that charging window. The platform will add chargers to the
                    site in order to accomplish the fleet charging within the
                    charging window.
                </p>

                <h2 className="mt-10 mb-4 text-2xl font-bold">
                    Reserved Charging
                </h2>
                <p>
                    This function is not yet available within the system and
                    therefore it is not available for selection.
                </p>

                <h2 className="mt-10 mb-4 text-2xl font-bold">
                    Operating Days
                </h2>
                <p>
                    You can select the operating days of the week that the
                    charging hub is available. This can be useful if a business
                    is closed on a certain day of the week and does not allow
                    charging.
                </p>

                <h2 className="mt-10 mb-4 text-2xl font-bold">
                    Save And Configure Project
                </h2>
                <p>
                    Once you have filled in the information, click on Save and
                    Configure Project, congratulations! You have started your
                    first project! If you wish to edit a project, you can simply
                </p>

                <div className="imageContainer my-4 flex align-middle items-center gap-4">
                    <span>click on the pencil icon:</span>
                    <img src={EditImage} className=" object-contain" />
                </div>

                <p>
                    If you no longer need the project, you can delete the
                    project by selecting the trash icon and then confirming
                    delete.{' '}
                    <b>
                        Note that once a project is deleted, it is no longer
                        recoverable and you will need to rebuild the entire
                        project.
                    </b>
                </p>
                <div className="imageContainer my-4">
                    <img src={DeleteImage} className=" object-contain" />
                </div>

                <h2 className="mt-10 mb-4 text-2xl font-bold">
                    Build your project
                </h2>
                <p>
                    From the project dashboard, select the project you wish to
                    build by clicking on the project title.
                </p>
                <div className="imageContainer my-4">
                    <img
                        src={BuildProjectImage}
                        className="w-full object-contain"
                    />
                    <img
                        src={BuildProjectImage2}
                        className="w-full object-contain my-4"
                    />
                </div>
                <p>
                    From this page you can start adding energy assets, define a
                    charging hub, add electric vehicles to your fleet, and enter
                    in the financial details of your project. Once you have
                    completed all needed tasks, the Optimize button will be
                    highlighted and you can submit the project for optimization.
                </p>

                <h2 className="mt-10 mb-4 text-2xl font-bold">EV Fleet</h2>
                <span className="my-2">
                    Clicking on the EV Fleet button will enable you to add
                    groups of vehicles to your project. Your group can contain
                    any number of vehicles from 1 to any whole number.
                </span>
                <span className="my-2">
                    Create separate groups when the groups differ in quantity,
                    service type like average distance traveled, vehicle HVAC
                    loads, topography the vehicle journeys through, charging
                    power, or battery capacity.
                </span>
                <span className="my-2">
                    For example, a large long haul truck could have a 600 kWh
                    battery and also have a 450 kWh battery. These two vehicles,
                    although the same make and model, have different energy
                    capacities and should have separate Vehicle Grouping.
                </span>

                <div className="imageContainer my-4">
                    <img src={evFleet} className="w-full object-contain" />
                    <img
                        src={evFleet2}
                        className="w-full object-contain my-4"
                    />
                </div>

                <span className="my-2">
                    Start by entering the <b>Vehicle Group</b> name, select the
                    EV Technology, these attributes are meta data:
                </span>
                <div className="ms-4 flex flex-col px-4 gap-2">
                    <span>
                        1. <b> BEV, </b>this is a battery electric vehicle
                    </span>
                    <span>
                        2. <b> HEV, </b>a hybrid electric vehicle where the
                        fossil fuel engine charges the battery assist drive
                    </span>
                    <span>
                        3. <b> PHEV, </b>a plug in hybrid vehicle
                    </span>
                    <span>
                        4. <b> FCEV, </b>a vehicle operated via fuel cell
                    </span>
                    <span>
                        5. <b> SEV, </b> a solar powered vehicle
                    </span>
                </div>

                <p className="my-2">
                    Then select the Charging Model. Note that the charging model
                    applies to all vehicles in the group. You can add additional
                    Vehicle Groups to cover different types of Charging Model.
                </p>
                <p className="my-2">
                    Then add in the vehicle make, class, and model.
                </p>
                <p className="my-2">
                    Add the <b>Average Daily Distance</b> the vehicle will
                    travel, this will be used to calculate the energy required
                    to recharge the vehicle, add in the <b>Battery Size</b>, the{' '}
                    <b>Battery Max Power</b>, select the type of charging, AC or
                    DC, and the energy efficiency of the vehicle expressed as
                    the energy required per km of travel.
                </p>

                <p className="my-2">
                    <b>Charging Window</b>, this enables the system to design
                    the charging need around an available charging window. For
                    example, a fleet of vehicles that needs to recharge
                    overnight between the hours of 8 pm (20:00) and 5 am
                    (05:00).
                    <br />
                    Click save to save the vehicle group.
                </p>

                <div className="imageContainer my-4">
                    <img src={evFleet3} className="w-full object-contain" />
                </div>

                <span className="my-4">
                    Once you have saved the Vehicle Group, it will show up in
                    your Vehicle Groupings dashboard. To edit the vehicle
                    grouping, simply click on the pencil icon and to delete
                    click on the trash icon.
                </span>
                <div className="imageContainer my-4">
                    <img src={ProjectList} className="w-full object-contain" />
                </div>

                <h2 className="mt-10 mb-4 text-2xl font-bold">
                    Add Charging Hub
                </h2>
                <span>
                    Within the project, click on the Charging Hub button to add
                    chargers (EVSE) to the project.
                </span>
                <div className="imageContainer my-4">
                    <img
                        src={AddCargingHub}
                        className="w-full object-contain"
                    />
                </div>
                <p className="my-4">
                    When you select the Advanced Option to allow the platform to
                    determine the optimal charger configuration. In this case
                    the platform consider charger groups added to the project
                    but will ultimately decide independently of any charger
                    groups added.
                </p>
                <span className="mb-2">
                    If you wish to specify your own Charging Hub select Add
                    Charging Grouping.
                </span>
                <div className="imageContainer my-4">
                    <img
                        src={AddCargingHub2}
                        className="w-full object-contain"
                    />
                    <img
                        src={AddCargingHub3}
                        className="w-full object-contain mt-4"
                    />
                </div>
                <p className="my-2">
                    Provide a name for the Charger Group. You can ignore the
                    Select charger grouping and go straight to the Quantity of
                    chargers, the max power of the charger, and the number of
                    sockets the charger has.
                </p>
                <p className="my-2">Power is allocated to the charger</p>
                <p className="my-2">
                    The quantity refers to the number of chargers within the
                    group. This quantity will be overruled by the platform
                    during optimization.
                </p>
                <p className="my-2">
                    Once you have entered in the data or selected a generic
                    charger profile, then select the save button and the Charger
                    Grouping will be saved to the project and shown in the
                    Charger Groupings dashboard.
                </p>
                <div className="imageContainer my-4">
                    <img
                        src={AddCargingHub4}
                        className="w-full object-contain"
                    />
                </div>

                <p className="my-4">
                    If you want to edit a Charger Grouping, click on the pencil
                    icon and you will come back to the entry fields where you
                    can edit the data, just be sure to save. Likewise, if you
                    wish to delete a Charger Grouping, then click on the trash
                    icon and confirm the deletion.
                </p>

                <h2 className="mt-10 mb-4 text-2xl font-bold">Energy System</h2>
                <p className="my-4">
                    To add energy assets to the projects, click on the Energy
                    System tab. You will see all assets that you have added to
                    your project in the list. To add additional assets, click
                    the Add Component button.
                </p>

                <div className="imageContainer my-4">
                    <img src={EnergySystem} className="w-full object-contain" />
                </div>

                <p className="my-2">
                    From the Add Component button you can add four types of
                    energy assets, a Producer, Consumer, Storage, or Transformer
                    (grid connection).
                </p>
                <p className="my-2">
                    A producer is an energy producer such as a Generator, Solar
                    PV Array, or Wind turbine.
                </p>
                <div className="imageContainer my-4">
                    <img
                        src={EnergySystem1}
                        className="w-full object-contain"
                    />
                    <img
                        src={EnergySystem2}
                        className="w-full object-contain my-4"
                    />
                </div>
                <span className="my-2">
                    When selecting a Diesel Generator, the options below are
                    available. Within the drop down, there are predefined
                    generators or you can add in your own specification.
                </span>
                <div className="imageContainer my-4">
                    <img
                        src={EnergySystem3}
                        className="w-full object-contain"
                    />
                </div>
                <h2 className="mt-10 mb-4 text-2xl font-bold">
                    Financial Report
                </h2>
                <p className="my-4">
                    Total Load: This is the total kWh energy. This should not be
                    part of the financial analysis and instead be included in
                    the visualization. Remove from financial report.
                </p>
                <div className="mx-4 px-4 flex flex-col gap-4">
                    <span>
                        1 . <b> Revenue:</b>
                        <p>
                            Revenue represents the total amount of money
                            generated by the project from its primary
                            operations, such as sales of electricity. It is
                            recognized when it is earned and realizable
                            regardless of when payment is received
                        </p>{' '}
                    </span>
                    <span>
                        2 . <b> OPEX (Operating Expenses): </b>
                        <p>
                            Operating expenses refer to the costs associated
                            with the project’s day-to-day operations and are
                            incurred to generate revenue. This is a total line
                            summing the individual OPEX catagories.
                        </p>{' '}
                    </span>
                    <span>
                        3 . <b> Electricity Cost: </b>
                        <p>
                            Electricity cost represents the expenses incurred by
                            a company for the consumption of electrical energy
                            in its operations. It is considered an operating
                            expense and is recorded on the income statement.
                        </p>{' '}
                    </span>
                    <span>
                        4 . <b>Grid-related OPEX: </b>
                        <p>
                            Grid-related operating expenses refer to costs
                            associated with the maintenance, operation, and
                            management of the electrical grid infrastructure.
                            These expenses are incurred by the project and may
                            include maintenance, repairs, and administrative
                            costs related to the grid.
                        </p>{' '}
                    </span>
                    <span>
                        5 . <b> PV-related OPEX</b>
                        <p>
                            PV (Photovoltaic) related operating expenses pertain
                            to the costs associated with the maintenance and
                            operation of solar photovoltaic systems used to
                            generate electricity. This may include maintenance,
                            cleaning, and repair costs for solar panels and
                            associated equipment
                        </p>{' '}
                    </span>
                    <span>
                        6 . <b> EV-related OPEX: </b>
                        <p>
                            EV (Electric Vehicle) related operating expenses
                            include costs associated with the operation and
                            maintenance of electric vehicle fleets, such as
                            charging infrastructure, vehicle maintenance, and
                            electricity expenses for charging.
                        </p>{' '}
                    </span>
                    <span>
                        7 . <b> Battery-related OPEX: </b>
                        <p>
                            Battery-related operating expenses cover costs
                            associated with the operation and maintenance of
                            energy storage systems, such as batteries used to
                            store electricity. This includes maintenance,
                            consumable part replacement, and operational costs.
                        </p>{' '}
                    </span>
                    <span>
                        8 . <b>CAPEX (Capital Expenditures): </b>
                        <p>
                            This is a total line of the individual CAPEX lines.
                            Capital expenditures refer to significant
                            investments into the project in assets that are
                            expected to provide long-term benefits. These
                            expenditures are capitalized on the balance sheet
                            and typically depreciated over time.
                        </p>{' '}
                    </span>
                    <span>
                        9 . <b>Consulting services: </b>
                        <p>
                            This captures the fees for services, consulting,
                            engineering, other services required to bring the
                            project into operational mode. It includes the
                            project setup and design fees for the BrightMerge
                            platform
                        </p>{' '}
                    </span>
                    <span>
                        10. <b>Grid Upgrade CAPEX:</b>
                        <p>
                            Grid upgrade capital expenditures refer to
                            investments made to upgrade or expand the electrical
                            grid infrastructure, such as installing new
                            sub-panels, distribution boxes, meters,
                            transformers, or transmission lines. These
                            investments are capitalized and depreciated over
                            time
                        </p>{' '}
                    </span>
                    <span>
                        11. <b>Microgrid Setup CAPEX: </b>
                        <p>
                            Microgrid setup capital expenditures represent the
                            costs incurred in establishing a microgrid, which is
                            a localized electrical grid that can operate
                            independently or in conjunction with the main grid.
                            This includes the installation of generation
                            sources, control systems, and distribution
                            infrastructure.
                        </p>{' '}
                    </span>
                    <span>
                        12. <b>Operational Profit: </b>
                        <p>
                            Operational profit, also known as operating profit
                            or operating income, is the profit a project earns
                            from its core operations after deducting operating
                            expenses. It does not include interest expenses,
                            taxes, or non-operating income and expenses.
                        </p>{' '}
                    </span>
                    <span>
                        13. <b>Setup Renewal Cost: </b>
                        <p>
                            Setup renewal cost represents the expenses
                            associated with renewing or updating the initial
                            setup of a particular asset, process, or system.
                            These costs are often treated as capital
                            expenditures if they provide long-term benefits.
                        </p>{' '}
                    </span>
                    <span>
                        14. <b>Overall Profit: </b>
                        <p>
                            Overall profit, also known as net profit or net
                            income, is the final profit figure after all
                            expenses, including operating expenses, interest,
                            taxes, and any other income or expenses, have been
                            accounted for. It is a measure of the project’s
                            overall financial performance.
                        </p>{' '}
                    </span>
                    <span>
                        15. <b>Cumulated Profit: </b>
                        <p>
                            Cumulated profit represents the total accumulated
                            profits or net income over a specific period. It is
                            the sum of all profits earned during that time
                            frame. The timeframe of cumulated profit is the
                            timeframe of the project.
                        </p>{' '}
                    </span>
                    <span>
                        16. <b>Cumulated Revenue: </b>
                        <p>
                            Cumulated revenue is the total accumulated revenue
                            earned by the project over a specific period. It is
                            the sum of all revenues generated during that time
                            frame. The time frame is defined as the project
                            duration
                        </p>{' '}
                    </span>
                    <span>
                        17. <b>Accumulated Profit:</b>
                        <p>
                            Accumulated profit is the total cumulative profit
                            retained by a project since its inception. It
                            reflects the retained earnings of the company and
                            may include profits from multiple accounting
                            periods.
                        </p>{' '}
                    </span>
                    <span>
                        18. <b>Technology Escalation Rate: </b>
                        <p>
                            A Technology Escalation Rate, in the context of an
                            energy asset within a power electrical system,
                            refers to the rate at which the costs and
                            performance characteristics of a specific technology
                            change or escalate over time. This rate is often
                            used in the energy sector, especially for renewable
                            energy technologies, to predict how the costs of a
                            particular energy asset (such as a solar panel or
                            wind turbine) and its performance will evolve in the
                            future.
                        </p>{' '}
                        <p className="my-4">
                            The Technology Escalation Rate takes into account
                            factors such as improvements in technology,
                            economies of scale, research and development
                            advancements, and market dynamics. It is a key
                            parameter in energy planning, investment
                            decision-making, and policy development. Here's a
                            breakdown of the concept:
                        </p>
                        <div className="mx-4 my-2 px-10">
                            <p>
                                1. Cost Escalation: Technology escalation rates
                                can be positive or negative, reflecting whether
                                the costs of the technology are expected to
                                increase or decrease over time. In the context
                                of renewable energy, a negative escalation rate
                                indicates that the costs are expected to
                                decrease as the technology becomes more
                                efficient and widespread. <br />
                                <br />
                                2. Performance Improvement: Technology
                                escalation rates also consider how the
                                performance of the technology may improve over
                                time. For instance, a solar panel's efficiency
                                may increase, allowing it to generate more
                                electricity from the same amount of sunlight.{' '}
                                <br />
                                <br />
                                3. Market Adoption: The rate may take into
                                account the expected growth in the adoption of
                                the technology. As a technology becomes more
                                popular and production scales up, costs often
                                decrease due to economies of scale.
                                <br />
                                <br />
                                4. Research and Development: Advances in
                                research and development can lead to
                                technological breakthroughs that affect costs
                                and performance. This is an important factor in
                                determining the technology escalation rate.{' '}
                                <br />
                                <br />
                                5. Policy and Incentives: Government policies,
                                incentives, and subsidies can also influence the
                                technology escalation rate. For example,
                                policies promoting renewable energy may
                                accelerate the adoption of solar panels and wind
                                turbines, thereby affecting their costs and
                                performance. <br />
                                <br />
                                6. Global and Local Market Dynamics: Regional
                                and global factors can impact the technology
                                escalation rate. Changes in supply chains, trade
                                dynamics, and geopolitical factors can all play
                                a role.
                            </p>
                        </div>
                        <p className="my-4">
                            The Technology Escalation Rate is used in energy
                            modeling and forecasting to estimate future costs
                            and benefits of energy assets. It helps stakeholders
                            make informed decisions about investing in
                            particular technologies and designing energy systems
                            that are cost-effective and sustainable. Accurate
                            estimation of this rate is crucial for long-term
                            energy planning and for setting realistic targets
                            for renewable energy deployment and energy
                            transition strategies.
                        </p>
                    </span>
                    <span>
                        19. <b>Cost of lost load: </b>
                        <p>
                            When electricity is not available, businesses may be
                            forced to shut down temporarily, leading to
                            financial losses. This can affect production, sales,
                            and overall economic productivity. The longer the
                            outage, the greater the economic impact.
                        </p>{' '}
                    </span>
                    <span>
                        20 <b>Maximum load Control: </b>
                        <p>
                            Maximum Load Control, also known as peak shaving or
                            peak demand management, is a strategy employed
                            within an energy system to control or limit the peak
                            electricity demand during periods when the demand is
                            at its highest. This approach aims to reduce
                            electricity costs and avoid overloading the grid
                            during times of peak demand, which can be costly and
                            strain the electrical infrastructure. Maximum Load
                            Control typically involves a combination of
                            technology, equipment, and strategies to manage
                            energy consumption during peak hours. Here's how it
                            works:
                        </p>{' '}
                        <div className="mx-4 my-2 px-10">
                            1. <b>Load Shedding:</b> During peak demand periods,
                            certain non-essential loads or processes may be
                            temporarily reduced or curtailed. For example, in an
                            industrial setting, less critical equipment may be
                            turned off or operated at a reduced capacity to
                            lower overall electricity demand. This is often done
                            in a controlled and automated manner. <br />
                            <br />
                            2. <b>Demand Response:</b> Maximum Load Control can
                            be implemented through demand response programs
                            where consumers, typically large industrial or
                            commercial users, voluntarily reduce their
                            electricity usage during peak hours. In exchange,
                            they may receive financial incentives or reduced
                            electricity rates.
                            <br />
                            <br /> 3. <b>Energy Storage:</b> Energy storage
                            systems, such as batteries, can be used to store
                            excess energy during non-peak hours and discharge it
                            during peak periods, thereby reducing the need for
                            electricity from the grid.
                            <br />
                            <br /> 4. <b>Distributed Generation:</b> Local power
                            generation, such as combined heat and power (CHP)
                            systems or on-site renewable energy sources, can
                            offset grid electricity demand during peak times.
                            <br />
                            <br /> 5. <b>Smart Grid Technologies:</b> Advanced
                            metering, sensors, and automation systems are used
                            to monitor and control energy consumption in
                            real-time, allowing for more precise load control
                            during peak hours.
                            <br />
                            <br /> 6. <b>Time-of-Use Pricing:</b> Consumers may
                            be incentivized to shift their energy-intensive
                            activities to off-peak hours when electricity rates
                            are lower. This can be encouraged through
                            time-of-use pricing structures.
                            <br />
                            <br /> 7. <b>Load Forecasting:</b> Accurate load
                            forecasting helps utilities and consumers anticipate
                            peak demand periods, allowing for proactive load
                            control measures
                        </div>
                        <h2>Benefits of Maximum Load Control:</h2>
                        <div className="mx-4 my-2 px-10">
                            1. <b>Cost Savings:</b> By reducing peak demand,
                            consumers can lower their electricity bills,
                            especially if they are subject to demand charges or
                            time-of-use pricing.
                            <br />
                            <br />
                            2. <b>Grid Reliability:</b> Peak shaving helps
                            prevent grid overloads, reducing the risk of power
                            outages and the need for expensive grid
                            infrastructure upgrades.
                            <br />
                            <br />
                            3. <b>Environmental Impact:</b> Lowering peak demand
                            can contribute to reduced greenhouse gas emissions,
                            as it may reduce the need for fossil fuel-based
                            peaker plants, which are often used during
                            high-demand periods.
                            <br />
                            <br />
                            4. <b>Efficiency:</b> Managing peak loads improves
                            the overall efficiency of the energy system by
                            reducing waste and stress on the grid.
                        </div>
                        <p className="my-4">
                            Maximum Load Control is a valuable tool in ensuring
                            the efficient and reliable operation of energy
                            systems while helping to meet sustainability and
                            cost reduction goals. It is especially important in
                            regions with high electricity demand fluctuations
                            and where peak demand charges significantly impact
                            electricity costs.
                        </p>
                    </span>
                    <span>
                        21 <b>Capital Cost per Unit: </b>
                        <p>
                            This is the cost of the unit including the
                            installation and commissioning of the unit.
                        </p>{' '}
                    </span>
                    <span>
                        22 <b>Fixed O&M Cost: </b>
                        <p>
                            This is the annualized cost to support the unit and
                            keep it in operational condition and includes
                            repairs, refurbishment, consumables, service fees,
                            transportation fees, legal fees, and government
                            regulatory fees
                        </p>{' '}
                    </span>
                    <span>
                        23 <b>Year Purchased: </b>
                        <p>
                            This is the year in which the purchase order for the
                            equipment, installation, and commission was offered
                            and accepted.
                        </p>{' '}
                    </span>
                    <span>
                        24 <b>Operational Year: </b>
                        <p>
                            This is the year in which the equipment is brought
                            into operation. 25. Expected Lifetime: The expected
                            lifetime is the time period in years which the
                            energy asset is in operation after which it is to be
                            decommissioned.
                        </p>{' '}
                    </span>
                    <span>
                        25 <b>Expected Lifetime: </b>
                        <p>
                            The expected lifetime is the time period in years
                            which the energy asset is in operation after which
                            it is to be decommissioned.
                        </p>{' '}
                    </span>
                    <span>
                        26 <b>Replaceable: </b>
                        <p>
                            When an energy asset has met the expected lifetime
                            it will be decommissioned, when this is checked yes,
                            the energy asset will be replaced incurring a new
                            CAPEX expense and OPEX expense revision
                        </p>{' '}
                    </span>
                    <span>
                        27 <b>Replacement Cost: </b>
                        <p>
                            This is the CAPEX expense required to replace the
                            energy asset
                        </p>{' '}
                    </span>
                    <span>
                        28 <b>Residual Value: </b>
                        <p>
                            This is the value the energy asset has at time of
                            decommissioning
                        </p>{' '}
                    </span>
                    <span>
                        29 <b>MACRS: </b>
                        <p>
                            MACRS, which stands for "Modified Accelerated Cost
                            Recovery System," is a method used in the United
                            States for the depreciation of tangible property,
                            including various assets used in businesses and
                            income-producing activities. It is a system
                            established by the Internal Revenue Service (IRS) to
                            determine the allowable depreciation deductions for
                            tax purposes. MACRS is designed to recover the cost
                            of these assets over a specified period, reflecting
                            their declining value as they age.
                            <br />
                            Key features of MACRS depreciation include:
                        </p>{' '}
                        <div className="mx-4 px-10 my-4">
                            1. <b>Accelerated Depreciation:</b> MACRS allows for
                            an accelerated depreciation schedule, meaning that a
                            larger portion of the asset's cost is depreciated in
                            the early years of its useful life. This helps
                            businesses reduce their taxable income in the near
                            term, providing potential tax benefits.
                            <br />
                            <br />
                            2. <b>Depreciation Periods:</b> MACRS assigns
                            specific recovery periods for various types of
                            assets, which are typically shorter than the actual
                            economic life of the asset. The recovery periods are
                            determined based on asset classes and are outlined
                            in IRS guidelines.
                            <br />
                            <br />
                            3. <b>Depreciation Methods:</b> MACRS uses two
                            depreciation methods: the double declining balance
                            method and the straight-line method. Most assets are
                            depreciated using the double declining balance
                            method, which provides a more accelerated write-off
                            in the earlier years.
                            <br />
                            <br />
                            4. <b>Half-Year Convention:</b> MACRS also employs a
                            half-year convention, meaning that assets are
                            assumed to be placed in service at the midpoint of
                            the tax year, regardless of when they are actually
                            acquired. This further accelerates depreciation
                            deductions.
                            <br />
                            <br />
                            5. <b>Bonus Depreciation:</b> In certain
                            circumstances, the IRS allows for bonus
                            depreciation, which enables businesses to deduct a
                            significant portion of the asset's cost in the year
                            it is placed in service.
                            <br />
                            <br />
                            6. <b>Section 179 Deduction:</b> Small businesses
                            may take advantage of the Section 179 deduction,
                            which allows for immediate expensing of certain
                            capital assets up to a specified limit, providing a
                            tax incentive for investment.
                        </div>
                        <p>
                            It's important to note that MACRS is specifically
                            for tax purposes and differs from financial
                            accounting methods, such as Generally Accepted
                            Accounting Principles (GAAP). Businesses use MACRS
                            to calculate depreciation deductions on their tax
                            returns, which can help lower their taxable income
                            and reduce their tax liability. The specific
                            depreciation percentages, recovery periods, and
                            conventions used under MACRS are subject to change
                            and can vary depending on the type of asset and the
                            year it was placed in service. Therefore, businesses
                            must consult IRS guidelines and tax professionals to
                            determine the applicable MACRS depreciation rates
                            and rules for their assets.
                        </p>
                    </span>
                </div>
            </div>
        </div>
    )
}
export default Documentation
