import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { OptimizationStatuses } from '../optimize/interfaces'
import { ProjectDataEntryProgressState } from './interfaces'

const initialState: ProjectDataEntryProgressState = {
    project: null,
    isEnergySystemCompleted: false,
    isEVFleetCompleted: false,
    isChargingHubCompleted: false,
    isGeneralDataCompleted: false,
    isTariffDataCompleted: false,
    isOptimizationInProgress: false,
    optimizationStatus: OptimizationStatuses.IN_PROGRESS,
}

export const projectDataEntryProgressSlice = createSlice({
    name: 'projectDataEntryProgress',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setFullData: (state, { payload }) => {
            state.project = payload
            state.isEnergySystemCompleted =
                !!payload.energy_system?.components?.filter(
                    (component: any) => component.type
                ).length
            state.isEVFleetCompleted = !!payload.fleet.vehicle_grouping.length
            state.isChargingHubCompleted =
                !!payload.auto_determine_chargers ||
                !!payload.charging_hub[0].charger_grouping.length
            state.isGeneralDataCompleted = !!payload.params_groups.length
            state.isTariffDataCompleted =
                (!!payload.flatratetariff.length &&
                    payload.tariff_type === 'synthetic') ||
                (payload.timeseries.filename !== 'default.xlsx' &&
                    payload.tariff_type === 'custom') ||
                (payload.tariff[0] && payload.tariff_type === 'utility')

            state.isOptimizationInProgress =
                payload.optimization?.status ===
                OptimizationStatuses.IN_PROGRESS
            state.optimizationStatus = payload.optimization?.status
        },
        updateEnergySystemData: (state, { payload }) => {
            state.isEnergySystemCompleted = payload
        },
        updateEVFleetData: (state, { payload }) => {
            state.isEVFleetCompleted = payload
        },
        updateChargingHubData: (state, { payload }) => {
            state.isChargingHubCompleted = payload?.chargingHubCompleted
            state.project.auto_determine_chargers =
                payload?.auto_determine_chargers
        },
        updateGeneralFinancialDetailsData: (state, { payload }) => {
            state.isGeneralDataCompleted = payload
        },
        updateTariffFinancialDetailsData: (state, { payload }) => {
            state.isTariffDataCompleted = payload
        },
        updateOptimizationStatus: (state, { payload }) => {
            state.isOptimizationInProgress = payload.isOptimizationInProgress
            state.optimizationStatus = payload.optimizationStatus
        },
    },
})

export const {
    setFullData,
    updateEnergySystemData,
    updateEVFleetData,
    updateChargingHubData,
    updateOptimizationStatus,
    updateGeneralFinancialDetailsData,
    updateTariffFinancialDetailsData,
} = projectDataEntryProgressSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectProjectDataEntryProgress = (state: RootState) =>
    state.projectDataEntryProgress

export default projectDataEntryProgressSlice.reducer
