import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { OptimizationState } from './interfaces'
import { runOptimize } from './optimizeAPI'

const initialState: OptimizationState = {
    status: 'idle',
    results: [],
    count: 0,
    next: null,
    previous: null,
}

// Async function for uploading the sequences.
export const optimize = createAsyncThunk(
    'optimization/run',
    async (projectId: string) => {
        const response = await runOptimize(projectId)
        return response.data
    }
)

export const optimizeSlice = createSlice({
    name: 'optimize',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetOptimizationData: (state) => {
            state.status = 'idle'
            state.results = []
            state.count = 0
            state.next = null
            state.previous = null
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(optimize.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(optimize.rejected, (state) => {
                // Here we reset auth info. to force a redirect to auth/login.
                // @todo It would be nice to display a message to the user that their session has expired.
                state.status = 'idle'
            })
            .addCase(optimize.fulfilled, (state, action) => {
                state.status = 'idle'
                state.results = [action.payload]
                state.count = 1
            })
    },
})

export const { resetOptimizationData } = optimizeSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectOptimization = (state: RootState) => state.optimize

export default optimizeSlice.reducer
