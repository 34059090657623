import { useContext, useMemo, useState } from 'react'
import SupportIcon from '@mui/icons-material/Support'
import { SidebarContext } from '../../context/SidebarContext'
import {
    SearchIcon,
    BellIcon,
    MenuIcon,
    OutlinePersonIcon,
    OutlineCogIcon,
    OutlineLogoutIcon,
} from '../../assets/icons'
import { Badge, Input, Dropdown, DropdownItem } from '@windmill/react-ui'
import { useNavigate } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import ProfileIcon from '../Icons/ProfileIcon'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectAuth } from '../../features/auth/authSlice'
import { loadProjectsPage } from '../../features/projects/projectsSlice'
import { debounce } from 'lodash'

function Header() {
    const { user } = useAppSelector(selectAuth)
    const { toggleSidebar } = useContext(SidebarContext)
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [isNotificationsMenuOpen, setIsNotificationsMenuOpen] =
        useState(false)
    const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false)
    const [showCreateProjectModal, setShowCreateProjectModal] = useState(false)

    function handleNotificationsClick() {
        setIsNotificationsMenuOpen(!isNotificationsMenuOpen)
    }

    function handleProfileClick() {
        setIsProfileMenuOpen(!isProfileMenuOpen)
    }

    const handleAction = (type: string) => {
        switch (type) {
            case 'createProject':
                setShowCreateProjectModal(true)
                break
        }
    }

    const onModalAction = (type: string, redirect_url: string = '') => {
        switch (type) {
            case 'createProject':
                setShowCreateProjectModal(false)
                navigate(redirect_url)
                break
            case 'navigateToTeam':
                setShowCreateProjectModal(false)
                navigate(redirect_url)
                break
        }
    }

    const handleChange = async (e: any) => {
        await dispatch(loadProjectsPage({ page: 1, search: e.target.value }))
    }

    const debouncedChangeHandler = useMemo(
        () => debounce(handleChange, 300),
        []
    )

    return (
        <header className="z-40 py-4 bg-white shadow-bottom dark:bg-gray-800">
            <div className="container flex items-center justify-between h-full px-6 mx-auto text-lime-600 dark:text-lime-300">
                {/* <!-- Mobile hamburger --> */}
                <button
                    className="p-1 mr-5 -ml-1 rounded-md lg:hidden focus:outline-none focus:shadow-outline-lime"
                    onClick={toggleSidebar}
                    aria-label="Menu"
                >
                    <MenuIcon className="w-6 h-6" aria-hidden="true" />
                </button>
                {/* <!-- Search input --> */}
                <div className="flex justify-center flex-1 lg:mr-32">
                    <div className="relative w-full max-w-xl mr-6 focus-within:text-lime-500">
                        {user && user.is_staff && (
                            <>
                                <div className="absolute inset-y-0 flex items-center pl-2">
                                    <SearchIcon
                                        className="w-4 h-4"
                                        aria-hidden="true"
                                    />
                                </div>
                                <Input
                                    className="pl-8 text-gray-700"
                                    placeholder="Search for projects"
                                    aria-label="Search"
                                    css={undefined}
                                    onChange={debouncedChangeHandler}
                                />
                            </>
                        )}
                    </div>
                    <div className="relative w-full max-w-xl mr-6 focus-within:text-lime-500">
                        {/*<button*/}
                        {/*    onClick={(e) => {*/}
                        {/*        e.preventDefault();*/}
                        {/*        handleAction('createProject')*/}
                        {/*    }}*/}
                        {/*    className="align-bottom inline-flex items-center justify-center cursor-pointer leading-5 transition-colors duration-150 font-medium focus:outline-none px-4 py-2 rounded-lg text-sm text-white bg-lime-600 border border-transparent active:bg-lime-600 hover:bg-lime-700 focus:ring focus:ring-lime-300"*/}
                        {/*    type="button"><span>Add Project</span>*/}
                        {/*    <AddIcon fill="white" className="ml-2 max-h-5"/>*/}
                        {/*</button>*/}
                    </div>
                </div>
                <ul className="flex items-center flex-shrink-0 space-x-6">
                    <li>
                        <Tooltip title="Customer Support" arrow>
                            <div>
                                <SupportIcon />
                            </div>
                        </Tooltip>
                    </li>
                    {/* <!-- Notifications menu --> */}
                    <li className="relative">
                        <button
                            className="relative align-middle rounded-md focus:outline-none focus:shadow-outline-lime"
                            onClick={handleNotificationsClick}
                            aria-label="Notifications"
                            aria-haspopup="true"
                        >
                            <BellIcon className="w-5 h-5" aria-hidden="true" />
                            {/* <!-- Notification badge --> */}
                            <span
                                aria-hidden="true"
                                className="absolute top-0 right-0 inline-block w-3 h-3 transform translate-x-1 -translate-y-1 bg-red-600 border-2 border-white rounded-full dark:border-gray-800"
                            ></span>
                        </button>

                        <Dropdown
                            align="right"
                            isOpen={isNotificationsMenuOpen}
                            onClose={() => setIsNotificationsMenuOpen(false)}
                        >
                            <DropdownItem
                                tag="a"
                                href="#"
                                className="justify-between"
                            >
                                <span>Messages</span>
                                <Badge type="danger">0</Badge>
                            </DropdownItem>
                            <DropdownItem
                                tag="a"
                                href="#"
                                className="justify-between"
                            >
                                <span>Sales</span>
                                <Badge type="danger">0</Badge>
                            </DropdownItem>
                            <DropdownItem onClick={() => alert('Alerts!')}>
                                <span>Alerts</span>
                            </DropdownItem>
                        </Dropdown>
                    </li>
                    {/* <!-- Profile menu --> */}
                    <li className="flex relative">
                        {user && (
                            <button
                                className="rounded-full focus:shadow-outline-lime focus:outline-none w-8 h-8"
                                onClick={handleProfileClick}
                                aria-label="Account"
                                aria-haspopup="true"
                            >
                                <ProfileIcon user={user} />
                            </button>
                        )}
                        <Dropdown
                            align="right"
                            isOpen={isProfileMenuOpen}
                            onClose={() => setIsProfileMenuOpen(false)}
                        >
                            <DropdownItem tag="a" href="/app/profile">
                                <OutlinePersonIcon
                                    className="w-4 h-4 mr-3"
                                    aria-hidden="true"
                                />
                                <span>Profile</span>
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => navigate('/app/settings')}
                            >
                                <OutlineCogIcon
                                    className="w-4 h-4 mr-3"
                                    aria-hidden="true"
                                />
                                <span>Settings</span>
                            </DropdownItem>
                            <DropdownItem
                                onClick={() => navigate('/app/logout')}
                            >
                                <OutlineLogoutIcon
                                    className="w-4 h-4 mr-3"
                                    aria-hidden="true"
                                />
                                <span>Log out</span>
                            </DropdownItem>
                        </Dropdown>
                    </li>
                </ul>
            </div>
            {/*<CreateProjectModal isOpen={showCreateProjectModal} onClose={() => {*/}
            {/*    setShowCreateProjectModal(false)*/}
            {/*}} onAction={onModalAction}/>*/}
        </header>
    )
}

export default Header
