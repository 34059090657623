import { useContext, useState } from 'react'

import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { AuthContext } from '../../context/AuthContext'
import { Label, Input, HelperText, Button } from '@windmill/react-ui'
import useQuery from '../../utils/useQuery'

function ResetPasswordForm() {
    const { resetPasswordConfirmation } = useContext(AuthContext)
    const [saved, setSaved] = useState(false)
    const query = useQuery()

    if (saved) {
        return (
            <p className="text-sm font-medium text-gray-600">
                Your password has been reset. Please login using your new
                password.
            </p>
        )
    }

    return (
        <Formik
            initialValues={{
                new_password1: '',
                new_password2: '',
            }}
            validationSchema={Yup.object().shape({
                new_password1: Yup.string()
                    .min(8, 'New password must be at least 8 characters')
                    .matches(
                        new RegExp('^.*[0-9].*$'),
                        'At least one number required'
                    )
                    .matches(
                        new RegExp('^.*[a-zA-Z].*$'),
                        'At least one letter required'
                    )
                    .required('Password is required'),
                new_password2: Yup.string()
                    .required('Password is required')
                    .oneOf(
                        [Yup.ref('new_password1'), null],
                        'The two passwords should match'
                    ),
            })}
            onSubmit={(
                { new_password1, new_password2 },
                { setStatus, setSubmitting, setErrors }
            ) => {
                setSubmitting(true)
                setStatus()
                resetPasswordConfirmation(
                    new_password1,
                    new_password2,
                    query.get('uid') ? query.get('uid') : '',
                    query.get('key') ? query.get('key') : ''
                )
                    .then((response: any) => {
                        setSaved(true)
                        setSubmitting(false)
                    })
                    .catch((error: any) => {
                        if (error.response) {
                            if (error.response.data.token?.length) {
                                setStatus('The link is expired')
                            } else {
                                setStatus(error.response.data.message)
                            }
                        } else {
                            setStatus('Some error occured. Please try again.')
                        }
                        setErrors(error.response.data)
                        setSubmitting(false)
                    })
            }}
        >
            {({ errors, status, touched, isSubmitting }) => (
                <Form>
                    <Label>
                        <span>New Password</span>
                        <Field
                            className="mt-1"
                            as={Input}
                            name="new_password1"
                            type="password"
                            placeholder="***************"
                        />
                        <ErrorMessage name="new_password1">
                            {(msg) => (
                                <HelperText valid={false}>{msg}</HelperText>
                            )}
                        </ErrorMessage>
                    </Label>
                    <Label>
                        <span>Confirm New Password</span>
                        <Field
                            className="mt-1"
                            as={Input}
                            name="new_password2"
                            type="password"
                            placeholder="***************"
                        />
                        <ErrorMessage name="new_password2">
                            {(msg) => (
                                <HelperText valid={false}>{msg}</HelperText>
                            )}
                        </ErrorMessage>
                    </Label>

                    <Button
                        className="mt-4"
                        block
                        type="submit"
                        value="submit"
                        disabled={isSubmitting}
                    >
                        Reset password
                    </Button>
                    {status && <HelperText valid={false}>{status}</HelperText>}
                </Form>
            )}
        </Formik>
    )
}

export default ResetPasswordForm
