import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { ProjectsState } from './interfaces'
import { getProjects } from './projectsAPI'

const initialState: ProjectsState = {
    status: 'idle',
    results: [],
    count: 0,
    next: null,
    previous: null,
}

// Async function for uploading the sequences.
export const loadProjectsPage = createAsyncThunk(
    'projects/getPage',
    async (data: { page: number; search: string }) => {
        const { page = 1, search = '' } = data
        const response = await getProjects(page, search)
        // The value we return becomes the `fulfilled` action payload
        return response.data
    }
)

export const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetProjects: (state) => {
            state.status = 'idle'
            state.results = []
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(loadProjectsPage.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(loadProjectsPage.fulfilled, (state, action) => {
                state.status = 'idle'
                state.results = action.payload.results
                state.count = action.payload.count
                state.next = action.payload.next
                state.previous = action.payload.previous
            })
    },
})

export const { resetProjects } = projectsSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectProjects = (state: RootState) => state.projects

export default projectsSlice.reducer
