import { config } from '../config/config'
import { axiosPrivate } from '../api/connector'
import axios from 'axios'

const apiUrl = config.api.url
const url = config.api.dataLibraryUrl

const createProject = (values: any) => {
    return axiosPrivate.post(`${apiUrl}/v1/projects/`, {
        ...values,
        status: 'draft',
    })
}

const editProject = (id: string, values: any) => {
    return axiosPrivate.patch(`${apiUrl}/v1/projects/${id}`, values)
}

const uploadOptimizationResults = (project: any, values: any) => {
    const optimization = project.optimization
    return axiosPrivate.patch(
        `${apiUrl}/v1/optimization/${optimization?.id}`,
        values
    )
}

const downloadProjectDetails = (project: any) => {
    return axiosPrivate({
        url: `${url}/optimize/download?customer_id=${project.user.id}&project_id=${project.id}&optimization_id=${project.optimization.id}`, //your url
        method: 'GET',
        responseType: 'blob',
    })
}

const createEnergySystem = async () => {
    // TO DO Implement creating Energy System
}

// @todo refactor this. Components should be created like any other entity.
const updateEnergySystem = async () => {
    // TO DO Implement editing Energy System
}

const loadPage = (page: number = 1) => {
    return axiosPrivate.get(`/v1/projects/?page=${page}`)
}

const getProject = (projectId: string) => {
    return axiosPrivate.get(`${apiUrl}/v1/projects/${projectId}`, {})
}

const deleteProject = (projectId: string) => {
    return axiosPrivate.delete(`${apiUrl}/v1/projects/${projectId}`, {})
}

const getProjectFull = (projectId: string) => {
    return axiosPrivate.get(`${apiUrl}/v1/projects/${projectId}/full/`, {})
}

const getVehicleGroupingsByFleetId = (
    fleetId: string,
    charging_model: Record<string, any>,
    page: number = 1
) => {
    return axiosPrivate.get('/v1/vehicle-grouping/', {
        params: { page: page, fleet: fleetId },
    })
}

const addVehicleGrouping = (values: any) => {
    return axiosPrivate.post('/v1/vehicle-grouping/', values)
}

const editVehicleGrouping = (groupingId: string, values: any) => {
    return axiosPrivate.patch(`/v1/vehicle-grouping/${groupingId}`, values)
}

const deleteVehicleGrouping = (groupingId: string) => {
    return axiosPrivate.delete(`/v1/vehicle-grouping/${groupingId}`, {})
}

const getChargerGroupingsByHubId = (hubId: string, page: number = 1) => {
    return axiosPrivate.get('/v1/charger-grouping/', {
        params: { page: page, charging_hub: hubId },
    })
}

const editSystemComponent = (id: string, values: any) => {
    return axiosPrivate.patch(`/v1/components/${id}`, values)
}

const addChargerGrouping = (values: any) => {
    return axiosPrivate.post('/v1/charger-grouping/', values)
}

const editChargerGrouping = (groupingId: string, values: any) => {
    return axiosPrivate.patch(`/v1/charger-grouping/${groupingId}`, values)
}

const deleteChargerGrouping = (groupingId: string) => {
    return axiosPrivate.delete(`/v1/charger-grouping/${groupingId}`, {})
}

const addGenericFinancialDetails = (values: any) => {
    return axiosPrivate.post('/v1/params-groups/', values)
}

const getGenericFinancialDetails = (projectId: string) => {
    return axiosPrivate.get('/v1/params-groups/', {
        params: { project: projectId },
    })
}
const editGenericFinancialDetails = (id: string, values: any) => {
    return axiosPrivate.patch(`/v1/params-groups/${id}`, values)
}
const getTariffFinancialDetails = (projectId: string) => {
    return axiosPrivate.get('/v1/flat-rate-tariff/', {
        params: { project: projectId },
    })
}
const addFlatRateTariffFinancialDetails = (values: any) => {
    return axiosPrivate.post('/v1/flat-rate-tariff/', values)
}
const editFlatRateTariffFinancialDetails = (id: string, values: any) => {
    return axiosPrivate.patch(`/v1/flat-rate-tariff/${id}`, values)
}
const deleteFlatRateTariffFinancialDetails = (id: string) => {
    return axiosPrivate.delete(`/v1/flat-rate-tariff/${id}`)
}
const getAdvancedTariffFinancialDetails = (projectId: string) => {
    return axiosPrivate.get('/v1/tariff/', {
        params: { project: projectId },
    })
}
const addAdvancedTariffFinancialDetails = (values: any) => {
    return axiosPrivate.post('/v1/tariff/', values)
}
const editAdvancedTariffFinancialDetails = (id: string, values: any) => {
    return axiosPrivate.patch(`/v1/tariff/${id}`, values)
}
const uploadTariffFile = (values: any) => {
    return axiosPrivate.post('/v1/tariff/file', values)
}
const deleteTariffFile = (id: string) => {
    return axiosPrivate.delete(`/v1/tariff/${id}`)
}

const getEnergySystemFinancialDetails = (id: string, page: number = 1) => {
    return axiosPrivate.get('/v1/components/', {
        params: { energy_system: id, page: page },
    })
}

const sendForgotSomething = (values: any) => {
    return axiosPrivate.post('/v1/miss-something/', values)
}

const editChargerHub = (id: string, values: any) => {
    return axiosPrivate.patch(`/v1/charging-hub/${id}`, values)
}

const uploadTimeseriesFile = (projectId: string, values: any) => {
    return axiosPrivate.post(`/v1/projects/${projectId}/timeseries`, values)
}

const getTimeseriesFile = (projectId: string, id: string) => {
    return axiosPrivate.get(`/v1/projects/${projectId}/timeseries/${id}`)
}

const deleteCustomTariffFile = (tariff_id: string) => {
    return axiosPrivate.delete(`/v1/custom-tariff/${tariff_id}`)
}

const downloadTimeseriesFile = (projectId: string, id: string) => {
    return axiosPrivate.get(
        `/v1/projects/${projectId}/timeseries/${id}/download`
    )
}
const downloadTariffTemplateFile = () => {
    return axiosPrivate.get(`/v1/tariff-template/download`)
}
const uploadCustomTariffFile = (projectId: string, values: any) => {
    return axiosPrivate.post(
        `/v1/projects/${projectId}/custom-tariff/upload`,
        values
    )
}

const getSensitivityFinancialDetails = (projectId: string) => {
    return axiosPrivate.get('v1/sensitivities', {
        params: { project: projectId },
    })
}

const addSensitivityFinancialDetails = (values: any) => {
    return axiosPrivate.post(`v1/sensitivities/`, values)
}

const updateTariffNotes = (
    projectId: string,
    notes: { tariff_notes: string }
) => {
    return axiosPrivate.patch(`/v1/projects/${projectId}`, notes)
}

const getTariffFile = (projectId: string) => {
    return axiosPrivate.get(`/v1/projects/${projectId}/tariff-file`)
}

const downloadFileTariff = (tariff_file_id: string) => {
    return axiosPrivate.get(`/v1/tariff/file/${tariff_file_id}`)
}

const uploadFileTariff = (projectId: string, values: any) => {
    return axiosPrivate.post(`/v1/projects/${projectId}/tariff-file`, values)
}

const deleteFileTariff = (tariff_file_id: string) => {
    return axiosPrivate.delete(`/v1/tariff/file/${tariff_file_id}`)
}

const getChooseTariff = (project_id: string, tariff: string) => {
    return axiosPrivate.get(
        `/v1/projects/${project_id}/choose-tariff?tariff_type=${tariff}`
    )
}

export const projectService = {
    // createEnergySystem,
    // updateEnergySystem,
    deleteTariffFile,
    createProject,
    editProject,
    getProject,
    getProjectFull,
    deleteProject,
    loadPage,
    uploadOptimizationResults,
    downloadProjectDetails,
    getVehicleGroupingsByFleetId,
    addVehicleGrouping,
    deleteVehicleGrouping,
    editVehicleGrouping,
    getChargerGroupingsByHubId,
    addChargerGrouping,
    deleteChargerGrouping,
    editChargerGrouping,
    addGenericFinancialDetails,
    getGenericFinancialDetails,
    editGenericFinancialDetails,
    editSystemComponent,
    getTariffFinancialDetails,
    addAdvancedTariffFinancialDetails,
    uploadTariffFile,
    editAdvancedTariffFinancialDetails,
    sendForgotSomething,
    editChargerHub,
    getAdvancedTariffFinancialDetails,
    addFlatRateTariffFinancialDetails,
    editFlatRateTariffFinancialDetails,
    getEnergySystemFinancialDetails,
    uploadTimeseriesFile,
    getTimeseriesFile,
    deleteCustomTariffFile,
    downloadTimeseriesFile,
    deleteFlatRateTariffFinancialDetails,
    downloadTariffTemplateFile,
    uploadCustomTariffFile,
    getSensitivityFinancialDetails,
    addSensitivityFinancialDetails,
    updateTariffNotes,
    getTariffFile,
    uploadFileTariff,
    deleteFileTariff,
    downloadFileTariff,
    getChooseTariff,
}
