import { useContext, Suspense, useEffect } from 'react'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { WindmillContext } from '@windmill/react-ui'
import './App.css'
import { useAppSelector } from './app/hooks'
import AccessibleNavigationAnnouncer from './components/General/AccessibleNavigationAnnouncer'
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
    Outlet,
} from 'react-router-dom'
import Landing from './pages/Landing'
import Layout from './components/layout/Layout'
import { ColorModeContext, Theme } from './context/MuiColorModeContext'
import Auth from './components/layout/Auth'
import Login from './pages/Login'
import CreateAccount from './pages/CreateAccount'
import ResetPassword from './pages/ResetPassword'
import ForgotPassword from './pages/ForgotPassword'
import { AuthContext } from './context/AuthContext'
import ProtectedRoutes from './components/Routes/ProtectedRoutes'
import routes from './routes'
import Dashboard from './pages/Dashboard'
import Page404 from './pages/404'
import ThemedSuspense from './components/General/ThemedSuspense'
import EmailVerification from './pages/EmailVerification'
import ExpiredEmail from './pages/ExpiredEmail'
import EmailVerified from './pages/EmailVerified'
import { selectAuth } from './features/auth/authSlice'
import dayjs from 'dayjs'
import LandingMinimal from './pages/LandingMinimal'
import { config } from './config/config'
import { DEFAULT_THEME } from './constants'
import PrivacyPolicy from './pages/PrivacyPolicy'
// console.log()
function App() {
    const { user } = useAppSelector(selectAuth)
    const { mode, toggleMode } = useContext(WindmillContext)
    const muiColorMode = useContext(ColorModeContext)

    const isNewUser = (user: any) => {
        const MINUTES_SINCE_JOINED_CONSIDERED_NEW = 5
        const age = dayjs().diff(dayjs(user.date_joined), 'minutes')
        return age < MINUTES_SINCE_JOINED_CONSIDERED_NEW
    }

    useEffect(() => {
        muiColorMode.setColorMode(user?.settings?.theme ?? DEFAULT_THEME)
        if (
            mode !== user?.settings?.theme ||
            (!user && mode !== DEFAULT_THEME)
        ) {
            toggleMode()
        }
    }, [user])

    const redirectAfterLogin = () => {
        if (user && isNewUser(user)) return '/app/welcome'
        if (user && !isNewUser(user)) return '/app/projects'
        return window.location.pathname + window.location.search
    }

    return (
        <>
            <GoogleOAuthProvider clientId={config.google.clientId}>
                <Router>
                    <AccessibleNavigationAnnouncer />
                    <Routes>
                        <Route
                            path="/auth/*"
                            element={
                                user ? (
                                    <Navigate to={redirectAfterLogin()} />
                                ) : (
                                    <Outlet />
                                )
                            }
                        >
                            <Route path="login" element={<Login />} />
                            <Route
                                path="create-account"
                                element={<CreateAccount />}
                            />
                            <Route
                                path="privacy-policy"
                                element={<PrivacyPolicy />}
                            />
                            <Route
                                path="forgot-password"
                                element={<ForgotPassword />}
                            />
                            <Route
                                path="reset-password"
                                element={<ResetPassword />}
                            />
                        </Route>
                        {/* Place new routes over this */}
                        <Route path="/app/*" element={<ProtectedRoutes />}>
                            <Route
                                path={''}
                                element={<Navigate to="/app/projects" />}
                            />
                            <Route
                                path={''}
                                element={<Navigate to="/app/users" />}
                            />
                            <Route path={''} element={<Layout />}>
                                {routes.map((route, i) => {
                                    return route.element ? (
                                        <Route key={i} {...route} />
                                    ) : null
                                })}
                                <Route
                                    path="*"
                                    element={
                                        <Suspense fallback={<ThemedSuspense />}>
                                            <Page404 />
                                        </Suspense>
                                    }
                                />
                            </Route>
                        </Route>

                        <Route
                            path="/verify-email"
                            element={<EmailVerification />}
                        />
                        <Route
                            path="/auth/email-expired/*"
                            element={<ExpiredEmail />}
                        />
                        <Route
                            path="/auth/email-verified/*"
                            element={<EmailVerified />}
                        />
                        <Route
                            index
                            element={
                                user ? (
                                    <Navigate to={redirectAfterLogin()} />
                                ) : (
                                    <LandingMinimal />
                                )
                            }
                        />
                    </Routes>
                </Router>
            </GoogleOAuthProvider>
        </>
    )
}

export default App
