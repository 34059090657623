import React from 'react'
import DesktopSidebar from './DesktopSidebar'
import MobileSidebar from './MobileSidebar'

function Sidebar() {
    return (
        <>
            <DesktopSidebar />
            <MobileSidebar />
        </>
    )
}

export default Sidebar
