import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { UsersState } from './interfaces'
import { getUsers } from './usersAPI'

const initialState: UsersState = {
    status: 'idle',
    results: [],
    count: 0,
    next: null,
    previous: null,
}

// Async function for uploading the sequences.
export const loadUsersPage = createAsyncThunk(
    'users/getPage',
    async (page: number = 1) => {
        const response = await getUsers(page)
        // The value we return becomes the `fulfilled` action payload
        return response.data
    }
)

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetUsers: (state) => {
            state.status = 'idle'
            state.results = []
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(loadUsersPage.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(loadUsersPage.fulfilled, (state, action) => {
                state.status = 'idle'
                state.results = action.payload.results
                state.count = action.payload.count
                state.next = action.payload.next
                state.previous = action.payload.previous
            })
    },
})

export const { resetUsers } = usersSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUsers = (state: RootState) => state.users

export default usersSlice.reducer
