import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { persistor, store } from './app/store'
import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'
import './assets/css/tailwind.output.css'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Windmill } from '@windmill/react-ui'
import Theme from './Theme'
import { AuthProvider } from './context/AuthContext'
import { SidebarProvider } from './context/SidebarContext'
import { SnackbarProvider } from './context/SnackbarContext'
import { PersistGate } from 'redux-persist/integration/react'
import { ProjectsProvider } from './context/ProjectsContext'
import { SnackbarProvider as NotiSnackbarProvider } from 'notistack'
import { MuiColorModeProvider } from './context/MuiColorModeContext'

const container = document.getElementById('root')!
const root = createRoot(container)

// Customize mui theme.
// const theme = createTheme({
//     palette: {
//         primary: {
//             main: '#75be56',
//         },
//         secondary: {
//             main: '#1b2a07',
//         },
//     },
// })

root.render(
    // <React.StrictMode>
    <PersistGate loading={null} persistor={persistor}>
        <Provider store={store}>
            <ProjectsProvider>
                <AuthProvider>
                    <SnackbarProvider>
                        <SidebarProvider>
                            <MuiColorModeProvider>
                                {/* @ts-ignore */}
                                <Windmill light usePreferences theme={Theme}>
                                    <NotiSnackbarProvider
                                        autoHideDuration={5000}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <App />
                                        </LocalizationProvider>
                                    </NotiSnackbarProvider>
                                </Windmill>
                            </MuiColorModeProvider>
                        </SidebarProvider>
                    </SnackbarProvider>
                </AuthProvider>
            </ProjectsProvider>
        </Provider>
    </PersistGate>
    // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
