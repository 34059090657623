import React, {
    useState,
    useMemo,
    useEffect,
    useCallback,
    useContext,
} from 'react'
import axios from 'axios'
import { config } from '../config/config'
import ThemedSuspense from '../components/General/ThemedSuspense'
//import connector from "../api/connector";
import { AuthContext } from './AuthContext'
import { useAppSelector } from '../app/hooks'
import { selectAuth } from '../features/auth/authSlice'
import { axiosPrivate } from '../api/connector'

const apiUrl = config.api.url

// Create context.
export const ProjectsContext = React.createContext<any>(null)

export const ProjectsProvider = ({ children }: { children: any }) => {
    const { user } = useContext(AuthContext)
    const authData: any = useAppSelector(selectAuth)
    const [isLoaded, setLoaded] = useState(true)
    const [projects, setProjects] = useState([])

    const loadProjects = useCallback((page: number = 1) => {
        axiosPrivate
            .get(`/v1/projects/?page=${page}`)
            .then((response: any) => {
                setLoaded(true)
                setProjects(response.data)
            })
            .catch((err: any) => {
                console.log(err)
                setLoaded(true)
            })
    }, [])

    const value = useMemo(() => {
        const loadPage = (page: number) => {
            loadProjects(page)
        }

        return {
            loadPage,
            projects,
        }
    }, [projects])

    if (!isLoaded) {
        return <ThemedSuspense />
    }

    return (
        <ProjectsContext.Provider value={value}>
            {children}
        </ProjectsContext.Provider>
    )
}
