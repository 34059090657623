/**
 * ⚠ These are used just to render the Sidebar!
 * You can include any link here, local or external.
 *
 * If you're looking to actual Router routes, go to
 * `routes/index.js`
 */
const routes = [
    {
        icon: 'FormsIcon',
        name: 'Learn',
        is_staff: false,
        routes: [
            // submenu
            // {
            //     path: '/app/blank',
            //     name: 'Video Tutorials',
            //     is_staff: false,
            // },
            {
                path: '/app/documentation',
                name: 'Documentation',
                is_staff: false,
            },
            // {
            //     path: '/app/blank',
            //     name: 'FAQs',
            //     is_staff: false,
            // },
        ],
    },
    {
        path: '/app/users',
        icon: 'PeopleIcon',
        name: 'Users',
        is_staff: true,
    },
    // {
    //   path: '/app/billing',
    //   icon: 'FormsIcon',
    //   name: 'Billing',
    //   is_staff: false,
    // },
    {
        path: '/app/projects',
        icon: 'ChartsIcon',
        name: 'Projects',
        is_staff: false,
    },
    {
        path: '/app/support',
        icon: 'OutlinePersonIcon',
        name: 'Customer Support',
        is_staff: false,
    },
    // {
    //   path: '/app/teams',
    //   icon: 'PagesIcon',
    //   name: 'Teams',
    //   is_staff: false,
    // },
    {
        path: '/app/logout',
        icon: 'OutlineLogoutIcon',
        name: 'Logout',
        is_staff: false,
    },
    {},
    // {
    //     path: '/app/dashboard', // the url
    //     icon: 'HomeIcon', // the component being exported from icons/index.js
    //     name: 'Dashboard', // name that appear in Sidebar
    //     is_staff: false,
    // },
    // {
    //   path: '/app/forms',
    //   icon: 'FormsIcon',
    //   name: 'Forms',
    // },
    // {
    //   path: '/app/cards',
    //   icon: 'CardsIcon',
    //   name: 'Cards',
    // },
    // {
    //   path: '/app/charts',
    //   icon: 'ChartsIcon',
    //   name: 'Charts',
    // },
    // {
    //   path: '/app/buttons',
    //   icon: 'ButtonsIcon',
    //   name: 'Buttons',
    // },
    // {
    //   path: '/app/modals',
    //   icon: 'ModalsIcon',
    //   name: 'Modals',
    // },
    // {
    //   path: '/app/tables',
    //   icon: 'TablesIcon',
    //   name: 'Tables',
    // },
]

export default routes
