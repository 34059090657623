import bgImage from '../../assets/img/2014256339-desktop.jpg'

const divStyle: any = {
    backgroundImage: `url(${bgImage})`,
}

const CoverImage = ({ children }: any) => {
    return (
        <div
            className="bg-cover bg-center"
            style={divStyle}
        >
            {children}
        </div>
    )
}

export default CoverImage
