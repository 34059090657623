import { UserData } from '../../features/auth/interfaces'
import { UserIcon } from '../../assets/icons'

export enum IconSizes {
    sm = 'sm',
    md = 'md',
}

interface ProfileIconProps {
    user: UserData
    size?: IconSizes
    editable?: boolean
    selectedAvatar?: any
    onSelectAvatar?: (file: any) => void
}

const getUserInitials = (user: UserData) =>
    `${user?.first_name?.slice(0, 1)}${user?.last_name.slice(0, 1)}`

const ProfileIcon = ({
    user,
    size = IconSizes.sm,
    editable,
    selectedAvatar,
    onSelectAvatar,
}: ProfileIconProps) => {
    const getClassNames = () => {
        const classes =
            'relative inline-flex justify-center overflow-hidden items-center rounded-full font-medium uppercase leading-7 text-gray-800 bg-gray-300 dark:text-gray-200 dark:bg-gray-700'
        switch (size) {
            case IconSizes.sm:
                return `${classes} w-8 h-8 text-sm`
            case IconSizes.md:
                return `${classes} w-20 h-20 text-3xl`
        }
    }

    const renderAvatar = () => {
        const avatar =
            (selectedAvatar && URL.createObjectURL(selectedAvatar)) ||
            user?.avatar ||
            ''
        if (avatar) {
            return (
                <img
                    src={avatar}
                    alt="Avatar"
                    className="object-cover h-full w-full"
                />
            )
        }
        return user?.first_name && user?.last_name ? (
            getUserInitials(user)
        ) : (
            <UserIcon
                fontSize="small"
                className="w-2/4 h-2/4 text-gray-300 text-3xl"
                aria-hidden="true"
            />
        )
    }

    return (
        <p className={getClassNames()}>
            {renderAvatar()}
            {editable && (
                <div className="absolute flex justify-center items-center h-1/3 w-full bottom-0 cursor-pointer">
                    <input
                        className="w-full h-full opacity-0 absolute"
                        type="file"
                        title=""
                        name="avatar"
                        onChange={(event) => {
                            if (event.target.files?.length && onSelectAvatar) {
                                onSelectAvatar(event.target.files[0])
                            }
                        }}
                    />
                    <label
                        className="flex justify-center items-center w-full h-full pointer-events-none cursor-pointer transition-all"
                        htmlFor="avatar"
                    >
                        <div className="absolute w-full h-full opacity-70 bg-gray-400 hover:opacity-80"></div>
                        <p className="text-black text-sm z-10">Edit</p>
                    </label>
                </div>
            )}
        </p>
    )
}

export default ProfileIcon
