import { Container } from '@mui/material'

function PrivacyPolicy() {
    return (
        <Container sx={{ py: 2 }}>
            <p className="text-xl font-medium mb-4">
                Privacy Statement and GDPR Compliance
            </p>
            <p className="text-xl font-medium mb-4">
                Last Updated: 15 September 2023
            </p>
            <p className="text-xl font-medium mb-4">
                This Privacy Statement ("Statement") outlines how BrightMerge
                LTD ("we," "us," or "our") collects, uses, discloses, and
                safeguards personal data when you use our Software as a Service
                (SaaS) platform ("Platform"). We are committed to protecting
                your privacy and complying with applicable data protection laws,
                including the General Data Protection Regulation (GDPR).
            </p>
            <ul>
                <li className="text-xl font-medium ">
                    <p className="mb-4">1. Data Controller</p>
                    <p className="mb-4">
                        Aviram Hadash
                        <br />
                        BrightMerge LTD
                        <br /> 2nd Floor, 28 Dam Maccabim Blvd.
                        <br /> Modiin, 71700 ISRAEL
                        <br /> dataprivacy@brightmerge.com
                    </p>
                </li>
                <li className="text-xl font-medium">
                    <p className="mb-4">2. Data We Collect</p>
                    <div>
                        <p className="mb-4">
                            We may collect the following types of personal data
                            when you log into our Platform:
                        </p>
                        <p className="mb-4">
                            User Information: This includes your name, email
                            address, username, and password.
                        </p>
                        <p className="mb-4">
                            Usage Information: We collect information about how
                            you use our Platform, such as your interactions,
                            settings, and preferences.
                        </p>
                        <p className="mb-4">
                            Device Information: We may collect information about
                            the device and browser you use to access our
                            Platform, including IP address, browser type, and
                            operating system.
                        </p>
                        <p className="mb-4">
                            Location Information: We may collect and process
                            location data if you grant us permission through
                            your device settings or browser.
                        </p>
                    </div>
                </li>
                <li className="text-xl font-medium mb-4">
                    <p className="mb-4">3. How We Use Your Data</p>
                    <p className="mb-4">
                        We use your personal data for the following purposes:
                    </p>
                    <p>To provide access to and improve our Platform.</p>
                    <p>
                        To communicate with you about your account and Platform
                        updates.
                    </p>
                    <p>To respond to your inquiries and support requests.</p>
                    <p>To analyze user behavior and improve our services.</p>
                    <p>
                        To comply with legal obligations and enforce our terms
                        of service.
                    </p>
                </li>
                <li className="text-xl font-medium mb-4">
                    <p className="mb-4">4. Legal Basis for Processing</p>
                    <p>
                        We process your personal data based on one or more of
                        the following legal grounds:
                        <br />
                        Processing is necessary for the performance of a
                        contract with you.
                        <br />
                        Contractual Necessity: Consent: You have given explicit
                        consent for us to process your data.
                        <br />
                        Legitimate Interests: Processing is necessary for our
                        legitimate interests, such as improving our services.
                        <br />
                        Legal Compliance: Processing is necessary to comply with
                        legal obligations.
                    </p>
                </li>
                <li className="text-xl font-medium mb-4">
                    <p className="mb-4">5. Data Sharing and Disclosure</p>
                    <p>
                        We may share your personal data with third parties under
                        the following circumstances:
                    </p>
                    <p>
                        With service providers who assist us in delivering and
                        maintaining our Platform.
                        <br /> To comply with legal obligations or respond to
                        lawful requests from authorities.
                        <br /> In connection with a merger, acquisition, or sale
                        of all or part of our business.
                    </p>
                </li>
                <li className="text-xl font-medium">
                    <p className="mb-4">6. International Data Transfers</p>
                    <p className="mb-4">
                        Your data may be transferred and stored in countries
                        outside of your own, including countries that may not
                        offer the same level of data protection. We take
                        appropriate measures to ensure your data is protected in
                        accordance with applicable data protection laws.
                    </p>
                </li>
                <li className="text-xl font-medium">
                    <p className="mb-4">7. Data Retention</p>
                    <p className="mb-4">
                        We retain your personal data for as long as necessary to
                        fulfill the purposes outlined in this Statement, unless
                        a longer retention period is required or permitted by
                        law.
                    </p>
                </li>
                <li className="text-xl font-medium">
                    <p className="mb-4">8. Your Rights</p>
                    <p className="mb-4">
                        You have rights under GDPR, including the right to
                        access, rectify, erase, or restrict the processing of
                        your personal data. You may also have the right to data
                        portability and to withdraw consent when processing is
                        based on consent.
                    </p>
                </li>
                <li className="text-xl font-medium">
                    <p className="mb-4">9. Contact Us</p>
                    <p className="mb-4">
                        If you have questions or concerns about this Statement,
                        your rights, or the processing of your personal data,
                        please contact us at dataprivacy@brightmerge.com.
                    </p>
                </li>
                <li className="text-xl font-medium">
                    <p className="mb-4">10. Changes to this Statement</p>
                    <p className="mb-4">
                        We may update this Statement from time to time to
                        reflect changes in our practices or for other
                        operational, legal, or regulatory reasons. Please review
                        this Statement periodically for any updates.
                    </p>
                    <p className="mb-4">
                        By using our Platform, you agree to the terms outlined
                        in this Privacy Statement.
                    </p>
                    <p className="font-bold">
                        General Data Protection Regulation (GDPR) Compliance
                        Clause
                    </p>
                    <ul>
                        <li>
                            <p className="font-bold ml-6">1. Introduction</p>
                            <p>
                                This GDPR Compliance Clause ("Clause") outlines
                                how Brightmerge L.T.D. ("we," "us," or "our")
                                collects, processes, and protects personal data
                                in compliance with the General Data Protection
                                Regulation (EU) 2016/679 ("GDPR").
                            </p>
                        </li>
                        <li>
                            <p className="font-bold ml-6">
                                2. Personal Data Collection and Processing
                            </p>
                            <p>
                                Brightmerge L.T.D. collects and processes
                                personal data from our users for the purpose of
                                providing our SaaS platform services and
                                improving user experience. Personal data may
                                include, but is not limited to, names, contact
                                information, billing details, and usage data.
                            </p>
                        </li>
                        <li>
                            <p className="font-bold ml-6">
                                3. Legal Basis for Processing
                            </p>
                            <p>
                                We process personal data based on the legal
                                grounds of contract performance, legitimate
                                interests, and consent. By using our SaaS
                                platform, users consent to the collection and
                                processing of their personal data as outlined in
                                this Clause.
                            </p>
                        </li>
                        <li>
                            <p className="font-bold ml-6">
                                4. Data Subject Rights
                            </p>
                            <p>
                                Users have the right to access, rectify, erase,
                                and restrict the processing of their personal
                                data. Users can exercise these rights by
                                contacting us at [contact email or address]. We
                                will respond to such requests in accordance with
                                GDPR requirements.
                            </p>
                        </li>
                        <li>
                            <p className="font-bold ml-6">5. Data Security</p>
                            <p>
                                We implement technical and organizational
                                measures to ensure the security and
                                confidentiality of personal data. However, no
                                data transmission over the internet can be
                                guaranteed as 100% secure. Users acknowledge and
                                accept this risk.
                            </p>
                        </li>
                        <li>
                            <p className="font-bold ml-6">
                                6. Data Sharing and Transfers
                            </p>
                            <p>
                                Personal data may be shared with third-party
                                service providers who assist us in delivering
                                our services. We ensure that such providers are
                                GDPR-compliant. We may also transfer personal
                                data to countries outside the European Economic
                                Area (EEA) based on appropriate safeguards.
                            </p>
                        </li>
                        <li>
                            <p className="font-bold ml-6">7. Data Retention</p>
                            <p>
                                We retain personal data for as long as necessary
                                to fulfill the purposes outlined in this Clause
                                or as required by applicable laws. Users can
                                request data deletion as described in section 4
                                above.
                            </p>
                        </li>
                        <li>
                            <p className="font-bold ml-6">
                                8. Cookies and Tracking Technologies
                            </p>
                            <p>
                                We use cookies and similar technologies to
                                enhance user experience and gather usage data.
                                Users can manage cookie preferences through
                                their browser settings.
                            </p>
                        </li>
                        <li>
                            <p className="font-bold ml-6">
                                9. Changes to this Clause
                            </p>
                            <p>
                                We reserve the right to modify this Clause to
                                reflect changes in our data processing
                                practices. Users will be notified of such
                                changes through our SaaS platform or other
                                appropriate means.
                            </p>
                        </li>
                        <li>
                            <p className="font-bold ml-6">
                                10. Contact Information
                            </p>
                            <p>
                                For any GDPR-related inquiries or concerns,
                                users can contact us at
                                dataprivacy@brightmerge.com.
                                <br />
                                By using our SaaS platform, users acknowledge
                                that they have read, understood, and agreed to
                                the terms of this GDPR Compliance Clause.
                            </p>
                        </li>
                    </ul>
                </li>
            </ul>
        </Container>
    )
}

export default PrivacyPolicy
