export interface Optimization {
    id: string
    status: string
    results: string
    project: string
}

export interface OptimizationState {
    status: 'idle' | 'loading' | 'failed'
    results: Optimization[]
    count: number
    next: any
    previous: any
}

export enum OptimizationStatuses {
    IN_PROGRESS = 'in progress',
    COMPLETED = 'completed',
    PUBLISHED = 'published',
}
