import { config } from '../config/config'
import { axiosPrivate } from '../api/connector'

export enum ComponentsTypes {
    PRODUCER = 'producer',
    CONSUMER = 'consumer',
    STORAGE = 'storage',
    GRID_CONNECTION = 'grid connection',
}

export const types: ComponentsTypes[] = [
    ComponentsTypes.PRODUCER,
    ComponentsTypes.CONSUMER,
    ComponentsTypes.STORAGE,
    ComponentsTypes.GRID_CONNECTION,
]

const apiUrl = config.api.url

const createComponent = (values: any) => {
    return axiosPrivate.post(`${apiUrl}/v1/components/`, values)
}

const updateComponent = (id: string, values: any) => {
    return axiosPrivate.patch(`${apiUrl}/v1/components/${id}`, values)
}

const deleteComponent = (componentId: string) => {
    return axiosPrivate.delete(`${apiUrl}/v1/components/${componentId}`)
}

const getComponents = (energySystemId: string, page: number) => {
    return axiosPrivate.get(`${apiUrl}/v1/components/`, {
        params: {
            energy_system: energySystemId,
            page,
        },
    })
}

const addFileToComponent = (values: any, startDate: string) => {
    return axiosPrivate.post(
        `${apiUrl}/v1/components/chart?start_date=${startDate}`,
        values
    )
}

const getLoadProfileChartData = (id: string, startDate: string) => {
    return axiosPrivate.get(
        `${apiUrl}/v1/components/${id}/chart?start_date=${startDate}`
    )
}

const resetLoadProfile = (id: string) => {
    return axiosPrivate.get(`${apiUrl}/v1/components/${id}/reset`)
}

export const componentService = {
    createComponent,
    updateComponent,
    deleteComponent,
    getComponents,
    addFileToComponent,
    resetLoadProfile,
    getLoadProfileChartData,
}
