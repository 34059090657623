import { Link, useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { Button } from '@windmill/react-ui'
import { emailsService } from '../services/emails.service'
import ImageLight from '../assets/img/forgot-password-office.jpeg'
import ImageDark from '../assets/img/forgot-password-office-dark.jpeg'
import useQuery from '../utils/useQuery'

function ExpiredEmail() {
    const navigate = useNavigate()
    const query = useQuery()
    const { enqueueSnackbar } = useSnackbar()
    const message = query.get('message')
    const email = query.get('email')

    const resendEmail = () => {
        emailsService
            .resendConfirmationEmail({ email: email })
            .then((response) => {
                enqueueSnackbar('The email is sent', {
                    variant: 'success',
                })
            })
            .catch((error) => {
                enqueueSnackbar(error.response.data.message, {
                    variant: 'error',
                })
            })
    }

    return (
        <div className="flex flex-col min-h-screen bg-gray-50 dark:bg-gray-900">
            <Link
                to="/"
                className="px-8 pt-8 text-xl font-bold text-gray-800 dark:text-gray-200"
            >
                BrightMerge
            </Link>
            <div className="flex flex-1 h-full items-center mt-4 lg:mt-0 p-6">
                <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden rounded-lg shadow-xl">
                    <div className="bg-white rounded-lg shadow-xl dark:bg-gray-800">
                        <div className="flex flex-col overflow-y-auto md:flex-row">
                            <div className="h-32 md:h-auto md:w-1/2">
                                <img
                                    aria-hidden="true"
                                    className="object-cover w-full h-full dark:hidden"
                                    src={ImageLight}
                                    alt="Office"
                                />
                                <img
                                    aria-hidden="true"
                                    className="hidden object-cover w-full h-full dark:block"
                                    src={ImageDark}
                                    alt="Office"
                                />
                            </div>
                            <main className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
                                <div className="w-full">
                                    <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                                        Confirm E-mail Address
                                    </h1>

                                    <p className="dark:text-gray-600">
                                        This e-mail confirmation link expired or
                                        is invalid.
                                    </p>
                                    {email && (
                                        <p className="dark:text-gray-600">
                                            Please
                                            <Button
                                                layout="link"
                                                className="text-sm font-medium text-lime-600 dark:text-lime-400 hover:underline hover:text-lime-600 dark:hover:text-lime-600 dark:hover:bg-transparent focus:ring-transparent py-0 px-0"
                                                onClick={() => resendEmail()}
                                            >
                                                issue a new e-mail confirmation
                                                request
                                            </Button>
                                            .
                                        </p>
                                    )}
                                    {message && (
                                        <p className="dark:text-gray-600">
                                            {message}
                                        </p>
                                    )}

                                    <hr className="my-8" />

                                    <p className="mt-4">
                                        <Button
                                            layout="primary"
                                            size="regular"
                                            aria-label="login"
                                            onClick={(e) => {
                                                navigate('/auth/login')
                                            }}
                                        >
                                            Log In
                                        </Button>
                                    </p>
                                </div>
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExpiredEmail
