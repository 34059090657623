import React from 'react'
import { createTheme } from '@mui/material/styles'
import { PaletteMode, ThemeProvider } from '@mui/material'

export enum Theme {
    DARK = 'dark',
    LIGHT = 'light',
}

export const ColorModeContext = React.createContext({
    toggleColorMode: () => {},
    setColorMode: (mode: any) => {},
})

export const MuiColorModeProvider = ({ children }: { children: any }) => {
    const [mode, setMode] = React.useState<Theme>(Theme.LIGHT)

    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode: Theme) =>
                    prevMode === Theme.LIGHT ? Theme.DARK : Theme.LIGHT
                )
            },
            setColorMode: (mode: Theme) => {
                setMode(mode)
            },
        }),
        []
    )

    const getDesignTokens = (mode: PaletteMode) => ({
        palette: {
            mode,
            ...(mode === Theme.LIGHT
                ? {
                      primary: {
                          main: '#65A30D',
                      },
                      secondary: {
                          main: '#1b2a07',
                      },
                  }
                : {
                      primary: {
                          main: '#e4eedd',
                      },
                      secondary: {
                          main: '#4bff38',
                      },
                  }),
        },
    })

    const theme = React.useMemo(
        () => createTheme(getDesignTokens(mode)),
        [mode]
    )

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ColorModeContext.Provider>
    )
}
