import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { projectService } from '../../services/project.service'

export interface ChargerGroupingsState {
    status: 'idle' | 'loading' | 'failed'
    results: []
    count: number
    next: any
    previous: any
}

const initialState: ChargerGroupingsState = {
    status: 'idle',
    results: [],
    count: 0,
    next: null,
    previous: null,
}

// Async function for uploading the sequences.
export const loadChargerGroupings = createAsyncThunk(
    'groupings/getChargerGroupings',
    async (data: { hubId: string; page: number }) => {
        const { hubId, page = 1 } = data
        const response = await projectService.getChargerGroupingsByHubId(
            hubId,
            page
        )
        return response.data
    }
)

export const chargerGroupingsSlice = createSlice({
    name: 'chargerGroupings',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        resetGroupings: (state) => {
            state.status = 'idle'
            state.results = []
        },
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(loadChargerGroupings.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(loadChargerGroupings.fulfilled, (state, action) => {
                state.status = 'idle'
                state.results = action.payload.results
                state.count = action.payload.count
                state.next = action.payload.next
                state.previous = action.payload.previous
            })
    },
})

export const { resetGroupings } = chargerGroupingsSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectChargerGroupings = (state: RootState) =>
    state.chargerGroupings

export default chargerGroupingsSlice.reducer
